import {navigate } from "gatsby"
import React, {useState, useContext, useEffect} from "react"
import {FirebaseContext} from '@components/Firebase'
import { Button } from '@components/common'
import FlexWrapper from '@common/layout/FlexWrapper'
import Form from '@form/Form'
import Input from '@form/Input'
import FlashMessage from '@notification/FlashMessage'
import Text from '@typo/Text'
import MainHeading from '@typo/MainHeading'
import { useTranslation } from 'react-i18next'

const ResetPassword = (props) => {
  const [formValues, setFormValues] = useState({email: ''});
  const [flashMessage, setFlashMessage] = useState('');

  const {firebase,user} = useContext(FirebaseContext);
  let isMounted = true;

  const { t } = useTranslation()

  
  // check lecure 59
  useEffect(() => {
    if(user){
      navigate(t('urls.home'));
    }
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      isMounted = false;
    }
  }, [user])

  
  function handleSubmit(e){
    e.preventDefault(); // prevent redirect after submit
    if(firebase){
      firebase.auth.sendPasswordResetEmail(formValues.email).catch(error => {
        if(isMounted){
          console.log(error);
          setFlashMessage({"message": error.message,"type":"error"});
        } 
      }).then(()=>{
        setFlashMessage({"message": t('reset-password.flashMessage-1'),"type":"success"});
      });
    }
  }


 

  function handleInputChange(e){
    
    e.persist(); // dont know why
    setFlashMessage(''); // clean error message when inputs change
    setFormValues(currentValues =>({
      ...currentValues,
      [e.target.name]: e.target.value
    }))
  }   


  return (
    <FlexWrapper around column>
    
    <Form onSubmit={handleSubmit} marginTopBot>
    <MainHeading center>{t('reset-password.sectionTitle')}</MainHeading>
    <Text center>{t('reset-password.text-1')}</Text>
      <Input insetShadow required value ={formValues.email} name="email" placeholder={t('reset-password.emailPlaceholder')} onChange={handleInputChange} type="email" />
      {flashMessage && <FlashMessage message={flashMessage}/>}
      <Button type="submit" block marginTop>
      {t('reset-password.resetButton')}
      </Button>
    </Form>
  </FlexWrapper>
  );
}

export default ResetPassword